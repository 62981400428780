import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ToasterModule } from 'angular2-toaster';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { RatingModule } from 'ngx-bootstrap/rating';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { BsDatepickerModule, DatepickerModule } from 'ngx-bootstrap/datepicker';

import { ColorsService } from './colors/colors.service';
import { ScrollableDirective } from './directives/scrollable/scrollable.directive';
import { CardFieldDirective } from './directives/card-field/card-field.directive';
import { SharedHeaderModule } from './module/header/shared-header.module';
import { PdfViewerModuleModule } from './module/pdf-viewer/pdf-viewer.module';
import { EarningTimerModule } from './module/earning-timer/earning-timer.module';
import { NgxSelectModule } from 'ngx-select-ex';
import { ClientCreativeFeedbacksModule } from './module/client-creative-feedbacks/client-creative-feedbacks.module';
import { RatingIconComponent } from './module/icon/rating-icon/rating-icon.component';
import { DecimalFormatPipeModule } from './pipes/decimal-format/decimal-format.pipe.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SkeletonLoaderComponent } from './module/skeleton-loader/skeleton-loader.component';
import { GearIconComponent } from './module/icon/gear-icon/gear-icon.component';
import { PurpleFolderIconComponent } from './module/icon/purple-folder-icon/purple-folder-icon.component';
import { PurpleProjectsIconComponent } from './module/icon/purple-projects-icon/purple-projects-icon.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ProjectsDetailviewStatsComponent } from './module/stats/projects.detailview.stats.component';
import { ProjectsDetailviewMoveToPendingComponent } from './module/move-to-pending/projects.detailview.move-to-pending.component';
import { ProjectsDetailviewInviteDesignersComponent } from './module/invite-designers/projects.detailview.invite-designers.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { BadgesModule } from './module/badges/badges.module';
import { AccountTabPillsComponent } from './module/account-tab-pills/account-tab-pills.component';
import { ObjectToIterablePipeModule } from './pipes/object-to-iterable/object-to-iterable.pipe.module';
import { UploadIconComponent } from './module/icon/upload-icon/upload-icon.component';
import { UserIconComponent } from './module/icon/user-icon/user-icon.component';
import { LockIconComponent } from './module/icon/lock-icon/lock-icon.component';
import { CartIconComponent } from './module/icon/cart-icon/cart-icon.component';
import { ReceiptIconComponent } from './module/icon/receipt-icon/receipt-icon.component';
import { CommentIconComponent } from './module/icon/comment-icon/comment-icon.component';
import { DownloadIconComponent } from './module/icon/download-icon/download-icon.component';
import { ShieldIconComponent } from './module/icon/shield-icon/shield-icon.component';
import { DefaultCardIconComponent } from './module/icon/default-card-icon/default-card-icon.component';
import { BackupCardIconComponent } from './module/icon/backup-card-icon/backup-card-icon.component';
import { TrashIconComponent } from './module/icon/trash-icon/trash-icon.component';
import { LinkIconComponent } from './module/icon/link-icon/link-icon.component';
import { TrueIconComponent } from './module/icon/true-icon/true-icon.component';
import { EditIconComponent } from './module/icon/edit-icon/edit-icon.component';
import { DeleteIconComponent } from './module/icon/delete-icon/delete-icon.component';
import { PlusIconComponent } from './module/icon/plus-icon/plus-icon.component';
import { NgxSummernoteModule } from 'ngx-summernote';
import { DeleteChatIconComponent } from './module/icon/delete-chat-icon/delete-chat-icon.component';
import { AttachmentIconComponent } from './module/icon/attachment-icon/attachment-icon.component';
import { EmojiIconComponent } from './module/icon/emoji-icon/emoji-icon.component';
import { RecordIconComponent } from './module/icon/record-icon/record-icon.component';
import { ShareIconComponent } from './module/icon/share-icon/share-icon.component';
import { LeftArrowIconComponent } from './module/icon/left-arrow-icon/left-arrow-icon.component';
import { RightArrowIconComponent } from './module/icon/right-arrow-icon/right-arrow-icon.component';

import { StatsComponent } from './module/clients/creatives/stats/stats/stats.component';

import { NotesComponent } from './module/clients/creatives/notes/notes/notes.component';
import { ProjectsChatsImageTagsComponent } from './module/projects-chats-image-tags/projects-chats-image-tags.component';
import { VideojsModule } from './module/videojs/videojs.module';
import { DateFormatPipeModule } from './pipes/date-format-pipe/dateFormat.pipe.module';
import { NewLinePipeModule } from './pipes/new-line/new-line.pipe.module';
import { ProjectsChatsFileComponent } from './module/projects-chats-file/projects-chats-file.component';
import { InfiniteLoaderDirective } from './directives/infinite-loader/infinite-loader.directive';
import { TextareaAutoHeightDirective } from './directives/textarea-autoheight/textarea-autoheight.directive';
import { FileUploaderComponent } from '../routes/projects/editview/file-uploder/file-uploader.component';
import { FilePreviewComponent } from './module/file-preview/file-preview.component';
import { LabelIconComponent } from './module/icon/label-icon/label-icon.component';

import { SmallEmojiIconComponent } from './module/icon/small-emoji-icon/small-emoji-icon.component';
import { CommentIconSmallComponent } from './module/icon/comment-icon-small/comment-icon-small.component';
import { DownloadIconSmallComponent } from './module/icon/download-icon-small/download-icon-small.component';
import { TagIconSmallComponent } from './module/icon/tag-icon-small/tag-icon-small.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { FileUploadModule } from 'ng2-file-upload';

// https://angular.io/styleguide#!#04-10
@NgModule({
	imports: [
		CommonModule,
		ObjectToIterablePipeModule,
		HttpClientModule,
		NgxSummernoteModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		ClientCreativeFeedbacksModule,
		NgxSelectModule,
		EarningTimerModule,
		BadgesModule,
		AccordionModule.forRoot(),
		AlertModule.forRoot(),
		ButtonsModule.forRoot(),
		CarouselModule.forRoot(),
		CollapseModule.forRoot(),
		DatepickerModule.forRoot(),
		DecimalFormatPipeModule,
		BsDatepickerModule.forRoot(),
		ModalModule.forRoot(),
		PaginationModule.forRoot(),
		ProgressbarModule.forRoot(),
		RatingModule.forRoot(),
		TabsModule.forRoot(),
		TimepickerModule.forRoot(),
		TooltipModule.forRoot(),
		PopoverModule.forRoot(),
		TypeaheadModule.forRoot(),
		BsDropdownModule.forRoot(),
		ToasterModule,
		SharedHeaderModule,
		PdfViewerModuleModule,
		InfiniteScrollModule,
		NgxSkeletonLoaderModule,
		DragDropModule,
		VideojsModule,
		DateFormatPipeModule,
		NewLinePipeModule,
		DragDropModule,
		FileUploadModule,
	],
	providers: [ColorsService],
	declarations: [
		ScrollableDirective,
		CardFieldDirective,
		RatingIconComponent,
		SkeletonLoaderComponent,
		ProjectsDetailviewStatsComponent,
		ProjectsDetailviewMoveToPendingComponent,
		ProjectsDetailviewInviteDesignersComponent,
		GearIconComponent,
		PurpleFolderIconComponent,
		PurpleProjectsIconComponent,
		AccountTabPillsComponent,
		UploadIconComponent,
		UserIconComponent,
		LockIconComponent,
		CartIconComponent,
		ReceiptIconComponent,
		CommentIconComponent,
		DownloadIconComponent,
		ShieldIconComponent,
		DefaultCardIconComponent,
		BackupCardIconComponent,
		TrashIconComponent,
		LinkIconComponent,
		TrueIconComponent,
		EditIconComponent,
		DeleteIconComponent,
		PlusIconComponent,
		DeleteChatIconComponent,
		EmojiIconComponent,
		AttachmentIconComponent,
		RecordIconComponent,
		ShareIconComponent,
		LeftArrowIconComponent,
		RightArrowIconComponent,
		NotesComponent,
		StatsComponent,
		ProjectsChatsImageTagsComponent,
		ProjectsChatsFileComponent,
		TextareaAutoHeightDirective,
		InfiniteLoaderDirective,
		CheckboxComponent,
		SmallEmojiIconComponent,
		CommentIconSmallComponent,
		DownloadIconSmallComponent,
		TagIconSmallComponent,
		LabelIconComponent,
		FileUploaderComponent,
		FilePreviewComponent
	],
	exports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		RouterModule,
		AccordionModule,
		AlertModule,
		ButtonsModule,
		CarouselModule,
		CollapseModule,
		DatepickerModule,
		DecimalFormatPipeModule,
		BsDatepickerModule,
		BsDropdownModule,
		ModalModule,
		PaginationModule,
		ProgressbarModule,
		RatingModule,
		TabsModule,
		TimepickerModule,
		TooltipModule,
		PopoverModule,
		TypeaheadModule,
		ToasterModule,
		ScrollableDirective,
		CardFieldDirective,
		SharedHeaderModule,
		RatingIconComponent,
		DragDropModule,
		SkeletonLoaderComponent,
		ProjectsDetailviewStatsComponent,
		ProjectsDetailviewMoveToPendingComponent,
		ProjectsDetailviewInviteDesignersComponent,
		NgxSelectModule,
		NgxSkeletonLoaderModule,
		BadgesModule,
		InfiniteScrollModule,
		GearIconComponent,
		PurpleFolderIconComponent,
		PurpleProjectsIconComponent,
		AccountTabPillsComponent,
		UploadIconComponent,
		UserIconComponent,
		LockIconComponent,
		CartIconComponent,
		ReceiptIconComponent,
		CommentIconComponent,
		DownloadIconComponent,
		ShieldIconComponent,
		DefaultCardIconComponent,
		BackupCardIconComponent,
		TrashIconComponent,
		LinkIconComponent,
		TrueIconComponent,
		EditIconComponent,
		DeleteIconComponent,
		PlusIconComponent,
		DeleteChatIconComponent,
		EmojiIconComponent,
		AttachmentIconComponent,
		RecordIconComponent,
		ShareIconComponent,
		LeftArrowIconComponent,
		RightArrowIconComponent,
		NotesComponent,
		StatsComponent,
		ProjectsChatsImageTagsComponent,
		ProjectsChatsFileComponent,
		TextareaAutoHeightDirective,
		InfiniteLoaderDirective,
		SmallEmojiIconComponent,
		CommentIconSmallComponent,
		DownloadIconSmallComponent,
		TagIconSmallComponent,
		CheckboxComponent,
		LabelIconComponent,
		FileUploaderComponent,
		FilePreviewComponent,
	],
})

// https://github.com/ocombe/ng2-translate/issues/209
export class SharedModule {
	static forRoot(): ModuleWithProviders<SharedModule> {
		return {
			ngModule: SharedModule,
		};
	}
}
