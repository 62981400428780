// page level configuration for page id and isTrack
export const ANALYTICS_PAGES = {
	'1': {
		id: 1,
		name: 'login',
		url: '/login',
		placements: {
			'1': {
				id: 1,
				description: 'Redirect',
				isTrack: false,
			},
			'2': {
				id: 2,
				description: 'from Logout to Login',
				isTrack: false,
			},
			'3': {
				id: 3,
				description: 'from /signup (Client) to Login',
				isTrack: false,
			},
			'4': {
				id: 4,
				description: 'from /app-signup (Designer) to Login',
				isTrack: false,
			},
			'5': {
				id: 5,
				description: 'from Unauthorized to Login',
				isTrack: false,
			},
		},
	},
	'2': {
		id: 2,
		name: 'signup',
		url: '/signup',
		placements: {
			'6': {
				id: 6,
				description: 'Redirect',
				isTrack: false,
			},
			'7': {
				id: 7,
				description: 'from Login /login',
				isTrack: false,
			},
		},
	},
	'3': {
		id: 3,
		name: 'logout',
		url: '/logout',
		placements: {
			'8': {
				id: 8,
				description: 'from Header Menu',
				isTrack: true,
			},
		},
	},
	'4': {
		id: 4,
		name: 'checkout',
		url: '/checkout',
		placements: {
			'9': {
				id: 9,
				description: 'Redirect',
				isTrack: false,
			},
		},
	},
	'5': {
		id: 5,
		name: 'login with link',
		url: '/login/:guid',
		placements: {
			'10': {
				id: 10,
				description: 'Redirect',
				isTrack: false,
			},
		},
	},
	'6': {
		id: 6,
		name: 'Designer Signup',
		url: '/app-signup',
		placements: {
			'11': {
				id: 11,
				description: 'Redirect',
				isTrack: false,
			},
		},
	},
	'7': {
		id: 7,
		name: 'Reset Password',
		url: '/password/reset',
		placements: {
			'12': {
				id: 12,
				description: 'Redirect',
				isTrack: false,
			},
			'13': {
				id: 13,
				description: 'from /login',
				isTrack: false,
			},
		},
	},
	'8': {
		id: 8,
		name: 'Reset Password Token',
		url: '/password/reset/:token',
		placements: {
			'14': {
				id: 14,
				description: 'Redirect',
				isTrack: false,
			},
		},
	},
	'9': {
		id: 9,
		name: 'Invite Sub-User Signup',
		url: '/invite-signup',
		placements: {
			'15': {
				id: 15,
				description: 'Redirect',
				isTrack: false,
			},
		},
	},
	'10': {
		id: 10,
		name: 'Not Found',
		url: '/404',
		placements: {
			'16': {
				id: 16,
				description: 'Redirect',
				isTrack: false,
			},
			'17': {
				id: 17,
				description: 'from Not found Page',
				isTrack: false,
			},
		},
	},
	'11': {
		id: 11,
		name: 'Design Share',
		url: '/share/:id',
		placements: {
			'18': {
				id: 18,
				description: 'Redirect',
				isTrack: false,
			},
		},
	},
	'12': {
		id: 12,
		name: 'Designer Profile Share',
		url: '/profiles/:id',
		placements: {
			'19': {
				id: 19,
				description: 'Redirect',
				isTrack: false,
			},
		},
	},
	'13': {
		id: 13,
		name: 'Onboarding after Designer Signup',
		url: '/onboarding',
		placements: {
			'20': {
				id: 20,
				description: 'Redirect',
				isTrack: true,
			},
			'21': {
				id: 21,
				description: 'After Signup',
				isTrack: true,
			},
			'22': {
				id: 22,
				description: 'After Login',
				isTrack: true,
			},
			'23': {
				id: 23,
				description: 'from Seamless',
				isTrack: true,
			},
		},
	},
	'14': {
		id: 14,
		name: 'Select Plan',
		url: '/plans',
		placements: {
			'24': {
				id: 24,
				description: 'Redirect',
				isTrack: true,
			},
			'25': {
				id: 25,
				description: 'After Signup',
				isTrack: true,
			},
			'26': {
				id: 26,
				description: 'After Login',
				isTrack: true,
			},
			'27': {
				id: 27,
				description: 'from Seamless',
				isTrack: true,
			},
		},
		events: {
			'95': {
				id: 95,
				description: 'Plan > Monthly',
				isTrack: true,
			},
			'96': {
				id: 96,
				description: 'Plan > Quarterly',
				isTrack: true,
			},
			'97': {
				id: 97,
				description: 'Plan > Yearly',
				isTrack: true,
			},
			'111': {
				id: 111,
				description: 'Plans Page > Quartely',
				isTrack: true,
			},
			'112': {
				id: 112,
				description: 'Plans Page > Annual',
				isTrack: true,
			},
			'113': {
				id: 113,
				description: 'Annual > Book Consultation',
				isTrack: true,
			},
		},
	},
	'15': {
		id: 15,
		name: 'Hooray',
		url: '/plans/hooray',
		placements: {
			'28': {
				id: 28,
				description: 'Redirect',
				isTrack: true,
			},
			'29': {
				id: 29,
				description: 'After Plan Purchased',
				isTrack: true,
			},
		},
	},
	'16': {
		id: 16,
		name: 'Dashboard',
		url: '/dashboard',
		placements: {
			'30': {
				id: 30,
				description: 'Redirect',
				isTrack: true,
			},
			'31': {
				id: 31,
				description: 'After Login',
				isTrack: true,
			},
			'32': {
				id: 32,
				description: 'from Sidebar',
				isTrack: true,
			},
			'33': {
				id: 33,
				description: 'from Unauthorized access',
				isTrack: true,
			},
			'34': {
				id: 34,
				description: 'from Flocksy logo',
				isTrack: true,
			},
		},
		events: {
			'1': {
				id: 1,
				description: 'Your Team is Forming > Creative Profile Overlay',
				isTrack: true,
			},
			'42': {
				id: 42,
				description: 'from Free Month Referral',
				isTrack: true,
			},
			'43': {
				id: 43,
				description: 'from flocksy facebook group',
				isTrack: true,
			},
			'44': {
				id: 44,
				description: 'from flocksy instagram group',
				isTrack: true,
			},
			'45': {
				id: 45,
				description: 'from sidebar > user block > Level',
				isTrack: true,
			},
			'46': {
				id: 46,
				description: 'from flocksy community group',
				isTrack: true,
			},
			'57': {
				id: 57,
				description: 'Campaign Overlay',
				isTrack: true,
			},
			'61': {
				id: 61,
				description: 'from Tutorial button',
				isTrack: true,
			},
			'62': {
				id: 62,
				description: 'from Model Tutorial button',
				isTrack: true,
			},
			'63': {
				id: 63,
				description: 'from Model Schedule a call button',
				isTrack: true,
			},
			'66': {
				id: 66,
				description:
					'Post Slack channel notifications for new projects in Flocksy',
				isTrack: true,
			},
			'67': {
				id: 67,
				description: 'Create Trello cards for new projects in Flocksy',
				isTrack: true,
			},
			'68': {
				id: 68,
				description: 'Add new comments in Flocksy to Trello cards',
				isTrack: true,
			},
			'69': {
				id: 69,
				description:
					'Get Slack channel messages for new Flocksy comments',
				isTrack: true,
			},
			'70': {
				id: 70,
				description:
					'Attach newly-delivered Flocksy files to Trello cards',
				isTrack: true,
			},
			'71': {
				id: 71,
				description:
					'Send direct messages in Slack for new file deliveries in Flocksy',
				isTrack: true,
			},
			'72': {
				id: 72,
				description: 'Create Flocksy projects from new Trello cards',
				isTrack: true,
			},
			'73': {
				id: 73,
				description:
					'Create Flocksy projects for new messages in Slack channels',
				isTrack: true,
			},
			'74': {
				id: 74,
				description:
					'Save attachments to Google Drive when new files are delivered in Flocksy',
				isTrack: true,
			},
			'75': {
				id: 75,
				description:
					'Save attachments to Google Drive when new files are delivered in Flocksy',
				isTrack: true,
			},
			'80': {
				id: 80,
				description: 'from Notification button',
				isTrack: true,
			},
			'88': {
				id: 88,
				description: 'Stop Timer',
				isTrack: true,
			},
			'89': {
				id: 89,
				description: 'Stop Timer Overlay > Stop Button',
				isTrack: true,
			},
			'98': {
				id: 98,
				description: 'Side menu > Disruptive Advertisement',
				isTrack: true,
			},
			'99': {
				id: 99,
				description: 'Header Notification Overlay > Past Notifications',
				isTrack: true,
			},
			'117': {
				id: 117,
				description: 'from Copy to Clipboard',
				isTrack: true,
			},
			'118': {
				id: 118,
				description: 'from Share on LinkedIn',
				isTrack: true,
			},
			'119': {
				id: 119,
				description: 'from Share on Twitter',
				isTrack: true,
			},
			'120': {
				id: 120,
				description: 'from Record Video Button',
				isTrack: true,
			},
			'121': {
				id: 121,
				description: 'from Send Message Button',
				isTrack: true,
			},
		},
	},
	'17': {
		id: 17,
		name: 'Create a Project',
		url: '/projects/create',
		placements: {
			'35': {
				id: 35,
				description: 'Redirect',
				isTrack: true,
			},
			'36': {
				id: 36,
				description: 'from Sidebar',
				isTrack: true,
			},
			'37': {
				id: 37,
				description: 'from Dashboard > Top Button',
				isTrack: true,
			},
			'38': {
				id: 38,
				description: 'from Dashboard > Team > Plus Icon',
				isTrack: true,
			},
			'39': {
				id: 39,
				description: 'from /projects/completed > Copy Brief',
				isTrack: true,
			},
			'40': {
				id: 40,
				description: 'from /projects/:id Detailview > Copy Brief',
				isTrack: true,
			},
			'192': {
				id: 192,
				description:
					'Dashboard > Popular Projects/ Trending Project Types',
				isTrack: true,
			},
			'194': {
				id: 194,
				describe: 'from Dashboard > Manage Plan',
				isTrack: true,
			},
			'197': {
				id: 197,
				description: "Create Project from Overlay",
				isTrack: true,
			}
		},
		events: {
			'2': {
				id: 2,
				description: '2. Invite/Exclude a Team Member',
				isTrack: true,
			},
			'3': {
				id: 3,
				description:
					'2. Invite/Exclude a Team Member > Suggested Team Member',
				isTrack: true,
			},
			'60': {
				id: 60,
				description: 'Hire Team Member Overlay',
				isTrack: true,
			},
			'92': {
				id: 92,
				description: 'Create a project page > Notes',
				isTrack: true,
			},
			'94': {
				id: 94,
				description: 'Create a project page > Stats',
				isTrack: true,
			},
			'104': {
				id: 104,
				description:
					'Create a marketplace project page > Create a New Card',
				isTrack: true,
			},
			'105': {
				id: 105,
				description: 'Create a marketplace project page > Payment',
				isTrack: true,
			},
			'108': {
				id: 108,
				description: 'Create a marketplace project page > Next Button',
				isTrack: true,
			},
			'109': {
				id: 109,
				description:
					'Create a marketplace project page > Click Creative Profile',
				isTrack: true,
			},
		},
	},
	'18': {
		id: 18,
		name: 'Pending Projects',
		url: '/projects/pending',
		placements: {
			'41': {
				id: 41,
				description: 'Redirect',
				isTrack: true,
			},
			'42': {
				id: 42,
				description: 'from Sidebar',
				isTrack: true,
			},
			'43': {
				id: 43,
				description: 'from Dashboard > Dashlets',
				isTrack: true,
			},
		},
	},
	'19': {
		id: 19,
		name: 'Active Projects',
		url: '/projects/active',
		placements: {
			'44': {
				id: 44,
				description: 'Redirect',
				isTrack: true,
			},
			'45': {
				id: 45,
				description: 'from Sidebar',
				isTrack: true,
			},
			'46': {
				id: 46,
				description: 'from Dashboard > Dashlets',
				isTrack: true,
			},
			'47': {
				id: 47,
				description: 'After login',
				isTrack: true,
			},
		},
		events: {
			'36': {
				id: 36,
				description: 'Recent Activity',
				isTrack: true,
			},
			'81': {
				id: 81,
				description: 'Reset platinum user timer',
				isTrack: true,
			},
		},
	},
	'20': {
		id: 20,
		name: 'Completed Projects',
		url: '/projects/completed',
		placements: {
			'48': {
				id: 48,
				description: 'Redirect',
				isTrack: true,
			},
			'49': {
				id: 49,
				description: 'from Sidebar',
				isTrack: true,
			},
			'50': {
				id: 50,
				description: 'from Dashboard > Dashlets',
				isTrack: true,
			},
		},
		events: {
			'87': {
				id: 87,
				description:
					'Completed > Copy Brief > Deleted Question Overlay',
				isTrack: true,
			},
		},
	},
	'21': {
		id: 21,
		name: 'Project Detailview',
		url: '/projects/:id',
		placements: {
			'51': {
				id: 51,
				description: 'Redirect',
				isTrack: true,
			},
			'52': {
				id: 52,
				description: 'from /projects/pending',
				isTrack: true,
			},
			'53': {
				id: 53,
				description: 'from projects/active',
				isTrack: true,
			},
			'54': {
				id: 54,
				description: 'from /projects/completed',
				isTrack: true,
			},
			'55': {
				id: 55,
				description: 'from projects/unassigned',
				isTrack: true,
			},
			'56': {
				id: 56,
				description: 'from projects/archived',
				isTrack: true,
			},
			'57': {
				id: 57,
				description: 'from Dashboard > Activity',
				isTrack: true,
			},
			'58': {
				id: 58,
				description: 'from Dashboard > Designer Profile',
				isTrack: true,
			},
			'59': {
				id: 59,
				description: 'from Team > Designer Profile',
				isTrack: true,
			},
			'60': {
				id: 60,
				description: 'from Dashboard > Recent Completed Projects',
				isTrack: true,
			},
			'61': {
				id: 61,
				description: 'from Admin > Outgoing Tx',
				isTrack: true,
			},
			'62': {
				id: 62,
				description: 'from /projects/create after successfully',
				isTrack: true,
			},
			'63': {
				id: 63,
				description: 'from /projects/create > Designer Profile',
				isTrack: true,
			},
			'100': {
				id: 100,
				description: 'Project detailview > Redo Project',
				isTrack: true,
			},
		},
		events: {
			'4': {
				id: 4,
				description: "Introducing Share Design! > See What's New",
				isTrack: true,
			},
			'5': {
				id: 5,
				description: 'Right Sidebar > Project Manager Chat',
				isTrack: true,
			},
			'6': {
				id: 6,
				description: 'Right Sidebar > Mark As Completed',
				isTrack: true,
			},
			'7': {
				id: 7,
				description: 'Right Sidebar > Chat with Client',
				isTrack: true,
			},
			'8': {
				id: 8,
				description: 'Right Sidebar > Chat with Team Member',
				isTrack: true,
			},
			'9': {
				id: 9,
				description: 'Bottom Chat > Project Manager Chat',
				isTrack: true,
			},
			'10': {
				id: 10,
				description: 'Bottom Chat > Finalize Project',
				isTrack: true,
			},
			'11': {
				id: 11,
				description: 'Project Menu > View Project Detail',
				isTrack: true,
			},
			'12': {
				id: 12,
				description: 'Project Menu > Load Old Chats',
				isTrack: true,
			},
			'13': {
				id: 13,
				description: 'Project Menu > Support',
				isTrack: true,
			},
			'14': {
				id: 14,
				description: 'Project Menu > Request Stock Images',
				isTrack: true,
			},
			'15': {
				id: 15,
				description: 'Project Menu > Project Brief',
				isTrack: true,
			},
			'16': {
				id: 16,
				description: 'Project Menu > Account Files',
				isTrack: true,
			},
			'17': {
				id: 17,
				description: 'Project Menu > Invite Designer',
				isTrack: true,
			},
			'18': {
				id: 18,
				description: 'Project Menu > Assign Users',
				isTrack: true,
			},
			'19': {
				id: 19,
				description: 'Project Menu > Finalize',
				isTrack: true,
			},
			'20': {
				id: 20,
				description: 'Project Menu > Add Turnaround Time',
				isTrack: true,
			},
			'21': {
				id: 21,
				description: 'Project Menu > +- Designer',
				isTrack: true,
			},
			'22': {
				id: 22,
				description: 'Project Menu > Client Info',
				isTrack: true,
			},
			'23': {
				id: 23,
				description: 'Project Menu > Bids',
				isTrack: true,
			},
			'24': {
				id: 24,
				description: 'Project Menu > Additional Payment',
				isTrack: true,
			},
			'25': {
				id: 25,
				description: 'Project Menu > Share Designs',
				isTrack: true,
			},
			'26': {
				id: 26,
				description: 'Project Menu > Archive Project',
				isTrack: true,
			},
			'47': {
				id: 47,
				description: 'Project Menu > Move to Pending',
				isTrack: true,
			},
			'52': {
				id: 52,
				description: 'Right Sidebar > Mark As Late’',
				isTrack: true,
			},
			'51': {
				id: 51,
				description: 'Project Menu > Request Additional Payment',
				isTrack: true,
			},
			'56': {
				id: 56,
				description: 'Project Menu > Stats',
				isTrack: true,
			},
			'64': {
				id: 64,
				description: 'Project Detailview Chat > Archive button',
				isTrack: true,
			},
			'65': {
				id: 65,
				description: 'Start project from draft > Archive button',
				isTrack: true,
			},
			'76': {
				id: 76,
				description: 'Project Detailview > Loom: Start Recording',
				isTrack: true,
			},
			'82': {
				id: 82,
				description: 'Reset platinum user timer',
				isTrack: true,
			},
			'83': {
				id: 83,
				description:
					'Project Detailview > Copy Brief > Deleted Question Overlay',
				isTrack: true,
			},
			'124' : {
				id: 124,
				description: 'View Licensing information',
				isTrack: true,
			}
		},
	},
	'22': {
		id: 22,
		name: 'Account Files',
		url: '/buckets',
		placements: {
			'64': {
				id: 64,
				description: 'Redirect',
				isTrack: true,
			},
			'65': {
				id: 65,
				description: 'from Sidebar',
				isTrack: true,
			},
			'66': {
				id: 66,
				description: 'from /projects/create',
				isTrack: true,
			},
		},
		events: {
			'27': {
				id: 27,
				description: 'List of Buckets > Edit',
				isTrack: true,
			},
			'28': {
				id: 28,
				description: 'List of Buckets > Delete',
				isTrack: true,
			},
		},
	},

	'23': {
		id: 23,
		name: 'Account File Detailview',
		url: '/buckets/:id',
		placements: {
			'67': {
				id: 67,
				description: 'Redirect',
				isTrack: true,
			},
			'68': {
				id: 68,
				description: 'from /buckets',
				isTrack: true,
			},
		},
		events: {
			'29': {
				id: 29,
				description: 'Edit',
				isTrack: true,
			},
			'30': {
				id: 30,
				description: 'Delete',
				isTrack: true,
			},
		},
	},
	'24': {
		id: 24,
		name: 'My Team',
		url: '/teams',
		placements: {
			'69': {
				id: 69,
				description: 'Redirect',
				isTrack: true,
			},
			'70': {
				id: 70,
				description: 'from Sidebar',
				isTrack: true,
			},
			'71': {
				id: 71,
				description: 'from Dashboard > Team forming',
				isTrack: true,
			},
		},
		events: {
			'31': {
				id: 31,
				description: 'View Profile Overlay',
				isTrack: true,
			},
			'59': {
				id: 59,
				description: 'Hire Team Member Overlay',
				isTrack: true,
			},
			'91': {
				id: 91,
				description: 'My team page > Notes',
				isTrack: true,
			},
			'93': {
				id: 93,
				description: 'My team page > Stats',
				isTrack: true,
			},
		},
	},
	'25': {
		id: 25,
		name: 'Users Invite Listview',
		url: '/users/invite',
		placements: {
			'72': {
				id: 72,
				description: 'Redirect',
				isTrack: true,
			},
			'73': {
				id: 73,
				description: 'from Sidebar',
				isTrack: true,
			},
		},
	},
	'26': {
		id: 26,
		name: 'Project Manager',
		url: '/messages',
		placements: {
			'74': {
				id: 74,
				description: 'Redirect',
				isTrack: true,
			},
		},
		events: {
			'32': {
				id: 32,
				description: 'Delete',
				isTrack: true,
			},
			'77': {
				id: 77,
				description: 'Message Center > Loom: Start Recording',
				isTrack: true,
			},
		},
	},
	'27': {
		id: 27,
		name: 'Template Library',
		url: '/template-library',
		placements: {
			'76': {
				id: 76,
				description: 'Redirect',
				isTrack: true,
			},
			'77': {
				id: 77,
				description: 'from Sidebar',
				isTrack: true,
			},
		},
		events: {
			'33': {
				id: 33,
				description: 'Creative Profile Overlay',
				isTrack: true,
			},
			'41': {
				id: 41,
				description: 'Template-library template details',
				isTrack: true,
			},
		},
	},
	'28': {
		id: 28,
		name: 'Profile',
		url: '/profile',
		placements: {
			'78': {
				id: 78,
				description: 'Redirect',
				isTrack: true,
			},
			'79': {
				id: 79,
				description: 'from Header Menu',
				isTrack: true,
			},
			'80': {
				id: 80,
				description: 'from Personal Settings',
				isTrack: true,
			},
		},
	},
	'29': {
		id: 29,
		name: 'Change Password',
		url: '/change-password',
		placements: {
			'81': {
				id: 81,
				description: 'Redirect',
				isTrack: true,
			},
			'82': {
				id: 82,
				description: 'from Header Menu',
				isTrack: true,
			},
			'83': {
				id: 83,
				description: 'from Personal Settings',
				isTrack: true,
			},
		},
	},
	'30': {
		id: 30,
		name: 'Billing Info',
		url: '/billing-info',
		placements: {
			'84': {
				id: 84,
				description: 'Redirect',
				isTrack: true,
			},
			'85': {
				id: 85,
				description: 'from Header Menu',
				isTrack: true,
			},
			'86': {
				id: 86,
				description: 'from Personal Settings',
				isTrack: true,
			},
		},
	},
	'31': {
		id: 31,
		name: 'Finance',
		url: '/finance',
		placements: {
			'87': {
				id: 87,
				description: 'Redirect',
				isTrack: true,
			},
			'88': {
				id: 88,
				description: 'from Header Menu',
				isTrack: true,
			},
		},
	},
	'32': {
		id: 32,
		name: 'Unassigned Projects',
		url: '/projects/unassigned',
		placements: {
			'89': {
				id: 89,
				description: 'Redirect',
				isTrack: true,
			},
			'90': {
				id: 90,
				description: 'from Sidebar',
				isTrack: true,
			},
		},
		events: {
			'34': {
				id: 34,
				description: 'Project Brief',
				isTrack: true,
			},
			'53': {
				id: 53,
				description: 'Decline Project',
				isTrack: true,
			},
			'5': {
				id: 5,
				description: 'View Stats',
				isTrack: true,
			},
			'55': {
				id: 55,
				description: 'View Offers',
				isTrack: true,
			},
		},
	},
	'33': {
		id: 33,
		name: 'My Bids',
		url: '/bids',
		placements: {
			'91': {
				id: 91,
				description: 'Redirect',
				isTrack: true,
			},
			'92': {
				id: 92,
				description: 'from Sidebar',
				isTrack: true,
			},
		},
		events: {
			'48': {
				id: 48,
				description: 'Project Brief',
				isTrack: true,
			},
		},
	},
	'34': {
		id: 34,
		name: 'Work Categories',
		url: '/projects/preferences',
		placements: {
			'93': {
				id: 93,
				description: 'Redirect',
				isTrack: true,
			},
			'94': {
				id: 94,
				description: 'from Sidebar',
				isTrack: true,
			},
			'95': {
				id: 95,
				description: 'from Header Menu',
				isTrack: true,
			},
			'96': {
				id: 96,
				description: 'from Personal Settings',
				isTrack: true,
			},
		},
	},
	'35': {
		id: 35,
		name: 'Showcase',
		url: '/showcases',
		placements: {
			'97': {
				id: 97,
				description: 'Redirect',
				isTrack: true,
			},
			'98': {
				id: 98,
				description: 'from Sidebar',
				isTrack: true,
			},
		},
		events: {
			'35': {
				id: 35,
				description: 'Your Showcases',
				isTrack: true,
			},
		},
	},
	'36': {
		id: 36,
		name: 'Team Member Rules',
		url: '/users/rules',
		placements: {
			'99': {
				id: 99,
				description: 'Redirect',
				isTrack: true,
			},
			'100': {
				id: 100,
				description: 'from Sidebar',
				isTrack: true,
			},
		},
	},
	'37': {
		id: 37,
		name: 'Share Profile',
		url: '/profile/share',
		placements: {
			'101': {
				id: 101,
				description: 'Redirect',
				isTrack: true,
			},
			'102': {
				id: 102,
				description: 'from Header Menu',
				isTrack: true,
			},
			'103': {
				id: 103,
				description: 'from Personal Settings',
				isTrack: true,
			},
		},
	},
	'38': {
		id: 38,
		name: 'Block Designer',
		url: '/admin/block-designers',
		placements: {
			'104': {
				id: 104,
				description: 'Redirect',
				isTrack: true,
			},
			'105': {
				id: 105,
				description: 'from Sidebar',
				isTrack: true,
			},
		},
		events: {
			'37': {
				id: 37,
				description: 'Update Rule',
				isTrack: true,
			},
		},
	},
	'39': {
		id: 39,
		name: 'Clients',
		url: '/clients',
		placements: {
			'106': {
				id: 106,
				description: 'Redirect',
				isTrack: true,
			},
			'107': {
				id: 107,
				description: 'from Sidebar',
				isTrack: true,
			},
		},
	},
	'40': {
		id: 40,
		name: 'View All Emails',
		url: '/emails',
		placements: {
			'108': {
				id: 108,
				description: 'Redirect',
				isTrack: true,
			},
			'109': {
				id: 109,
				description: 'from Send Email',
				isTrack: true,
			},
		},
	},
	'41': {
		id: 41,
		name: 'Send Email',
		url: '/emails/send',
		placements: {
			'110': {
				id: 110,
				description: 'Redirect',
				isTrack: true,
			},
			'111': {
				id: 111,
				description: 'from Header Menu',
				isTrack: true,
			},
			'170': {
				id: 170,
				description:
					'from View All Emails Listview /emails > Send Email',
				isTrack: true,
			},
		},
	},
	'42': {
		id: 42,
		name: 'Admin > Archived Projects',
		url: '/projects/archived',
		placements: {
			'112': {
				id: 112,
				description: 'Redirect',
				isTrack: true,
			},
			'113': {
				id: 113,
				description: 'from Sidebar',
				isTrack: true,
			},
		},
	},
	'43': {
		id: 43,
		name: 'Admin > Plans Page',
		url: '/admin/plans',
		placements: {
			'114': {
				id: 114,
				description: 'Redirect',
				isTrack: true,
			},
			'115': {
				id: 115,
				description: 'from Sidebar',
				isTrack: true,
			},
		},
		events: {
			'38': {
				id: 38,
				description: 'Update Turnaround',
				isTrack: true,
			},
		},
	},
	'44': {
		id: 44,
		name: 'Admin > Create a Plan',
		url: '/admin/plans/create',
		placements: {
			'116': {
				id: 116,
				description: 'Redirect',
				isTrack: true,
			},
			'117': {
				id: 117,
				description: 'from Sidebar',
				isTrack: true,
			},
		},
	},
	'45': {
		id: 45,
		name: 'Admin > Edit a Plan',
		url: '/admin/plans/:id/edit',
		placements: {
			'118': {
				id: 118,
				description: 'Redirect',
				isTrack: true,
			},
			'119': {
				id: 119,
				description: 'from Sidebar',
				isTrack: true,
			},
		},
	},
	'46': {
		id: 46,
		name: 'Admin > Users',
		url: '/admin/users',
		placements: {
			'120': {
				id: 120,
				description: 'Redirect',
				isTrack: true,
			},
			'121': {
				id: 121,
				description: 'from Sidebar',
				isTrack: true,
			},
		},
	},
	'47': {
		id: 47,
		name: 'Admin > Bids',
		url: '/admin/bids',
		placements: {
			'122': {
				id: 122,
				description: 'Redirect',
				isTrack: true,
			},
			'123': {
				id: 123,
				description: 'from Sidebar',
				isTrack: true,
			},
		},
	},
	'48': {
		id: 48,
		name: 'Admin > Template Library',
		url: '/admin/template-libraries',
		placements: {
			'124': {
				id: 124,
				description: 'Redirect',
				isTrack: true,
			},
			'125': {
				id: 125,
				description: 'from Sidebar',
				isTrack: true,
			},
		},
	},
	'49': {
		id: 49,
		name: 'Admin > Template Library > add',
		url: '/admin/template-libraries/create',
		placements: {
			'126': {
				id: 126,
				description: 'Redirect',
				isTrack: true,
			},
			'127': {
				id: 127,
				description: 'from Sidebar',
				isTrack: true,
			},
		},
	},
	'50': {
		id: 50,
		name: 'Admin > Template Library > edit',
		url: '/admin/template-libraries/:id/edit',
		placements: {
			'128': {
				id: 128,
				description: 'Redirect',
				isTrack: true,
			},
			'129': {
				id: 129,
				description: 'from Sidebar',
				isTrack: true,
			},
		},
	},
	'51': {
		id: 51,
		name: 'Admin > Designer Showcase',
		url: '/admin/showcases',
		placements: {
			'130': {
				id: 130,
				description: 'Redirect',
				isTrack: true,
			},
			'131': {
				id: 131,
				description: 'from Sidebar',
				isTrack: true,
			},
		},
	},
	'52': {
		id: 52,
		name: 'Admin > Project Settings',
		url: '/admin/project-settings',
		placements: {
			'132': {
				id: 132,
				description: 'Redirect',
				isTrack: true,
			},
			'133': {
				id: 133,
				description: 'from Sidebar',
				isTrack: true,
			},
		},
		events: {
			'39': {
				id: 39,
				description: 'Project Types > Edit',
				isTrack: true,
			},
			'40': {
				id: 40,
				description: 'Project Titles > Edit',
				isTrack: true,
			},
		},
	},
	'53': {
		id: 53,
		name: 'Admin > Project Questions',
		url: '/admin/project-questions',
		placements: {
			'134': {
				id: 134,
				description: 'Redirect',
				isTrack: true,
			},
			'135': {
				id: 135,
				description: 'from Sidebar',
				isTrack: true,
			},
		},
	},
	'54': {
		id: 54,
		name: 'Admin > Project Questions > Add',
		url: '/admin/project-questions/create',
		placements: {
			'136': {
				id: 136,
				description: 'Redirect',
				isTrack: true,
			},
			'137': {
				id: 137,
				description: 'from Sidebar',
				isTrack: true,
			},
		},
	},
	'55': {
		id: 55,
		name: 'Admin > Project Questions > Edit',
		url: '/admin/project-questions/:id/edit',
		placements: {
			'138': {
				id: 138,
				description: 'Redirect',
				isTrack: true,
			},
			'139': {
				id: 139,
				description: 'from Sidebar',
				isTrack: true,
			},
		},
	},
	'56': {
		id: 56,
		name: 'Admin > Outgoing Tx',
		url: '/admin/transactions/outgoing',
		placements: {
			'140': {
				id: 140,
				description: 'Redirect',
				isTrack: true,
			},
			'141': {
				id: 141,
				description: 'from Sidebar',
				isTrack: true,
			},
		},
	},
	'57': {
		id: 57,
		name: 'Admin > Incoming Tx',
		url: '/admin/transactions/incoming',
		placements: {
			'142': {
				id: 142,
				description: 'Redirect',
				isTrack: true,
			},
			'143': {
				id: 143,
				description: 'from Sidebar',
				isTrack: true,
			},
		},
	},
	'58': {
		id: 58,
		name: 'Admin > Reports > Rating',
		url: '/admin/reports/rating',
		placements: {
			'144': {
				id: 144,
				description: 'Redirect',
				isTrack: true,
			},
			'145': {
				id: 145,
				description: 'from Sidebar',
				isTrack: true,
			},
		},
	},
	'59': {
		id: 59,
		name: 'Admin > PM Setting',
		url: '/admin/pm-signup',
		placements: {
			'146': {
				id: 146,
				description: 'Redirect',
				isTrack: true,
			},
			'147': {
				id: 147,
				description: 'from Sidebar',
				isTrack: true,
			},
		},
	},
	'60': {
		id: 60,
		name: 'Post a Bid',
		url: '/projects/:id/bids/create',
		placements: {
			'148': {
				id: 148,
				description: 'Redirect',
				isTrack: true,
			},
			'149': {
				id: 149,
				description: 'from Unassigned Projects > Bid',
				isTrack: true,
			},
			'150': {
				id: 150,
				description: 'from Unassigned Projects > Project Brief',
				isTrack: true,
			},
			'176': {
				id: 176,
				description: 'from Offer > Make Offer',
				isTrack: true,
			},
		},
	},
	'61': {
		id: 61,
		name: 'Edit a Bid',
		url: '/bids/:id/edit',
		placements: {
			'151': {
				id: 151,
				description: 'Redirect',
				isTrack: true,
			},
			'152': {
				id: 152,
				description: 'from Unassigned Projects > Edit Bid',
				isTrack: true,
			},
			'153': {
				id: 153,
				description:
					'from Unassigned Projects > Project Brief > Edit Bid',
				isTrack: true,
			},
			'154': {
				id: 154,
				description: 'from My Bids > Edit',
				isTrack: true,
			},
		},
	},
	'62': {
		id: 62,
		name: 'Uploading Design',
		url: '/admin/onboarding/designs',
		placements: {
			'155': {
				id: 155,
				description: 'Redirect',
				isTrack: true,
			},
			'156': {
				id: 156,
				description: 'from Sidebar',
				isTrack: true,
			},
		},
	},
	'63': {
		id: 63,
		name: 'Uploading Design > Add',
		url: '/admin/onboarding/designs/create',
		placements: {
			'157': {
				id: 157,
				description: 'Redirect',
				isTrack: true,
			},
			'158': {
				id: 158,
				description: 'from Listview',
				isTrack: true,
			},
		},
	},
	'64': {
		id: 64,
		name: 'Uploading Design > Edit',
		url: '/admin/onboarding/designs/:id/edit',
		placements: {
			'159': {
				id: 159,
				description: 'Redirect',
				isTrack: true,
			},
			'160': {
				id: 160,
				description: 'from Listview',
				isTrack: true,
			},
		},
	},
	'65': {
		id: 65,
		name: 'Uploading Test',
		url: '/admin/onboarding/tests',
		placements: {
			'161': {
				id: 161,
				description: 'Redirect',
				isTrack: true,
			},
			'162': {
				id: 162,
				description: 'from Sidebar',
				isTrack: true,
			},
		},
	},
	'66': {
		id: 66,
		name: 'Uploading Test > Add',
		url: '/admin/onboarding/tests/create',
		placements: {
			'163': {
				id: 163,
				description: 'Redirect',
				isTrack: true,
			},
			'164': {
				id: 164,
				description: 'from Listview',
				isTrack: true,
			},
		},
	},
	'67': {
		id: 67,
		name: 'Uploading Test > Edit',
		url: '/admin/onboarding/tests/:id/edit',
		placements: {
			'165': {
				id: 165,
				description: 'Redirect',
				isTrack: true,
			},
			'166': {
				id: 166,
				description: 'from Listview',
				isTrack: true,
			},
		},
	},
	'68': {
		id: 68,
		name: 'User Profile Detailview',
		url: '/users/:id',
		placements: {
			'167': {
				id: 167,
				description: 'Redirect',
				isTrack: true,
			},
			'168': {
				id: 168,
				description: 'from /admin/users',
				isTrack: true,
			},
			'169': {
				id: 169,
				description: 'from /admin/reports/rating',
				isTrack: true,
			},
		},
	},
	'69': {
		id: 69,
		name: 'Feedback',
		url: '/feedback',
		placements: {
			'171': {
				id: 171,
				description: 'Redirect',
				isTrack: true,
			},
			'172': {
				id: 172,
				description: 'from Sidebar',
				isTrack: true,
			},
		},
		events: {
			'58': {
				id: 58,
				description: 'Dispute Rating',
				isTrack: true,
			},
		},
	},
	'70': {
		id: 70,
		name: 'Outgoing Tx with Creatives',
		url: '/admin/transactions/outgoing/transactions',
		placements: {
			'174': {
				id: 174,
				description: 'Redirect',
				isTrack: true,
			},
			'175': {
				id: 175,
				description: 'from Outgoing Tx',
				isTrack: true,
			},
		},
	},
	'71': {
		id: 71,
		name: 'Plan Billing Info',
		url: '/plans/billing-info',
		placements: {
			'177': {
				id: 177,
				description: 'Redirect',
				isTrack: true,
			},
			'178': {
				id: 178,
				description: 'From API',
				isTrack: true,
			},
		},
	},
	'73': {
		id: 73,
		name: 'Tests Awaiting Approval',
		url: '/creatives/onboarding/tests',
		placements: {
			'181': {
				id: 181,
				description: 'Redirect',
				isTrack: true,
			},
			'182': {
				id: 182,
				description: 'from Sidebar',
				isTrack: true,
			},
		},
	},
	'72': {
		id: 72,
		name: 'Project Files',
		url: '/project-files',
		placements: {
			'179': {
				id: 179,
				description: 'Redirect',
				isTrack: true,
			},
			'180': {
				id: 180,
				description: 'from Sidebar',
				isTrack: true,
			},
		},
		events: {
			'49': {
				id: 49,
				description: 'Project Files > Image',
				isTrack: true,
			},
			'50': {
				id: 50,
				description: 'All Files',
				isTrack: true,
			},
		},
	},
	'74': {
		id: 74,
		name: 'Additional Payments',
		url: '/admin/transactions/additional-payments',
		placements: {
			'179': {
				id: 183,
				description: 'Redirect',
				isTrack: true,
			},
			'180': {
				id: 184,
				description: 'from Sidebar',
				isTrack: true,
			},
		},
	},
	'75': {
		id: 75,
		name: 'Dispute Ratings',
		url: '/dispute-ratings',
		placements: {
			'185': {
				id: 185,
				description: 'Redirect',
				isTrack: true,
			},
			'186': {
				id: 186,
			},
		},
	},
	'76': {
		id: 76,
		name: 'Active and pending projects',
		url: '/projects/queue',
		events: {
			'84': {
				id: 84,
				description:
					'Queue > Active Projects > Copy Brief > Deleted Question Overlay',
				isTrack: true,
			},
			'85': {
				id: 85,
				description:
					'Queue > Pending Projects > Copy Brief > Deleted Question Overlay',
				isTrack: true,
			},
		},
	},
	'77': {
		id: 77,
		name: 'Draft projects',
		url: '/projects/drafts',
		events: {
			'86': {
				id: 86,
				description: 'Drafts > Copy Brief > Deleted Question Overlay',
				isTrack: true,
			},
		},
	},
	'78': {
		id: 78,
		name: 'Finance and billing',
		url: '/finance-and-billing',
		placements: {
			'193': {
				id: 193,
				description: 'Upgrading and Billing > Manage Subscription',
				isTrack: true,
			},
			'195': {
				id: 195,
				describe: 'Upgrading and Billing > Manage Plan',
				isTrack: true,
			},
			'196': {
				id: 196,
				describe: 'Right Side Menu > Upgrading and Billing',
				isTrack: true,
			},
		},
		events: {
			'101': {
				id: 101,
				description: 'Finance and Billing > Add Card',
				isTrack: true,
			},
			'102': {
				id: 102,
				description: 'Finance and Billing > Make Default',
				isTrack: true,
			},
			'103': {
				id: 103,
				description: 'Finance and Billing > Make Backup',
				isTrack: true,
			},
		},
	},
	'79': {
		id: 79,
		name: 'Marketplace login',
		url: '/marketplace-login',
		placements: {
			'106': {
				id: 106,
				description: 'Marketplace login',
				isTrack: true,
			},
		},
	},
	'80': {
		id: 80,
		name: 'Marketplace Signup',
		url: '/marketplace-signup',
		placements: {
			'107': {
				id: 107,
				description: 'Marketplace Signup',
				isTrack: true,
			},
		},
	},
	'81': {
		id: 81,
		name: 'Manage Subscription',
		url: '/marketplace-signup',
		events: {
			'109': {
				id: 109,
				description: 'Manage Subscription Page > Quarterly',
				isTrack: true,
			},
			'110': {
				id: 110,
				description: 'Manage Subscription Page > Annual',
				isTrack: true,
			},
		},
	},
	'82': {
		id: 82,
		name: 'Account Activity',
		url: '/activity-feed',
		placements: {
			'198': {
				id: 198,
				description: 'from Sidebar',
				isTrack: true,
			}
		},
		events: {
			'122': {
				id: 122,
				description: 'Clear Filter',
				isTrack: true,
			},
			'123': {
				id: 123,
				description: 'Apply Filter',
				isTrack: true,
			},
		}
	}
};

// global analytics service configuration
export const IS_ANALYTICS = true;
